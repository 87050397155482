import { CardProps } from '../../types/cards/card-props';
import './ZiCard.scss';
import {
	AlertVariant,
	Badge,
	Dropdown,
	DropdownItem,
	Flex,
	KebabToggle,
	Tooltip,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderBlank } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { CardTypesEnum } from '../../enums/card-types.enum';
import { faPenToSquare, faTrashAlt, faCube, faEye } from '@fortawesome/pro-regular-svg-icons';
import { Permission } from '../../enums/permission.enum';
import { Action } from '../table/ZiTable';
import { useMount } from 'react-use';
import { Folder } from '../../api/folders/Folder';
import { useIsGranted } from '../user/ApplicationProvider';
import DeleteConfirmationModal from '../../helpers/helper-components/DeleteConfirmationModal';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import AddFoldersModal, { AddFoldersModalProps } from '../modals/folders/AddFoldersModal';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useNavigate } from 'react-router';
import { Chart } from '../../api/charts/Chart';
import { Dataframe } from '../../api/dataframes/Dataframes';
import { Dashboard } from '../../api/dashbboards/Dashboards';
import { Report } from '../../api/reports/Reports';
import { faCircleUser } from '@fortawesome/pro-duotone-svg-icons';

function ZiCard(props: CardProps) {
	const [toUrl, setUrl] = useState<string>('');
	const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState<boolean>(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isFolderModalOpen, setIsFolderModalOpen] = useState<boolean>(false);
	const [normalizedActions, setNormalizedActions] = useState<Action<any>[]>([]);
	const isGranted = useIsGranted();
	const hasEditChartPerm = isGranted(Permission.EditChart);
	const hasEditReportPerm = isGranted(Permission.EditReport);
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const [isOpen, setIsOpen] = React.useState(false);
	const { addToast } = useToast();
	const [hasMinimumPermissions, setHasMinimumPermissions] = useState<boolean>(false);
	const navigate = useNavigate();
	const dropdownItemsRef = useRef<(HTMLAnchorElement | null)[]>([]);

	useMount(() => {
		const actions: Action<any>[] = [
			{
				name: (
					<>
						{hasEditChartPerm ? (
							<FontAwesomeIcon icon={faPenToSquare} />
						) : (
							<FontAwesomeIcon icon={faEye} />
						)}

						{hasEditChartPerm ? 'Edit' : 'View'}
					</>
				),
				callback: () => {
					onSelectEdit();
				},
				permission: Permission.EditFolder,
			},
			{
				name: (
					<>
						<FontAwesomeIcon icon={faTrashAlt} /> Delete
					</>
				),
				callback: () => {
					onSelectDelete();
				},
				permission: Permission.DeleteFolder,
			},
		];

		const localNormalizedActions = actions.filter((action) => {
			if (action.permission) {
				return isGranted(action.permission);
			}

			return true;
		});
		setNormalizedActions(localNormalizedActions);
		setHasMinimumPermissions(localNormalizedActions.length > 0);
	});

	const onSelectDelete = () => {
		setIsDeleteFolderModalOpen(true);
	};

	const handleFolderModalToggle = () => {
		const toggleValue = !isFolderModalOpen;
		setIsFolderModalOpen(toggleValue);
	};

	const onSelectEdit = () => {
		switch (props.type) {
			case CardTypesEnum.Chart:
				navigate(`/analyze/charts/build/chart/${props.id!}`);
				break;
			case CardTypesEnum.Dashboard:
				navigate(`/analyze/dashboards/${props.id!}`);
				break;
			case CardTypesEnum.DataFrame:
				navigate(`/analyze/dataframes/edit/${props.id!}`);
				break;
			case CardTypesEnum.Folders:
				handleFolderModalToggle();
				break;
			case CardTypesEnum.Report:
				if (hasEditReportPerm) {
					navigate(`/report/edit/${props.id!}`);
				} else if (hasViewReportPerm) {
					navigate(`/report/view/${props.id!}`);
				}
				break;
		}
	};

	const handleMouseEnter = (isFocusEvent = false) => {
		setIsHovered(true);

		if (isFocusEvent == false) {
			setIsOpen(false);
		}
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const closeDeleteModal = () => {
		setIsDeleteFolderModalOpen(false);
	};

	const DeleteFolder = () => {
		const folderId = props.id!;
		if (folderId != 0) {
			Folder.DeleteFolder(folderId)
				.then((response) => {
					closeDeleteModal();

					if (response.success) {
						props.refreshList && props.refreshList();
					}
				})
				.catch(() => {
					addToast('An error occured while Deleting the Folder', AlertVariant.danger);
				});
		}
	};

	const DeleteChart = () => {
		const chartId = props.id!;
		if (chartId != 0) {
			Chart.Delete(chartId)
				.then((response) => {
					closeDeleteModal();

					if (response.success) {
						props.refreshList && props.refreshList();
					}
				})
				.catch(() => {
					addToast('An error occured while Deleting the Chart', AlertVariant.danger);
				});
		}
	};

	const DeleteDataframe = () => {
		const dataframeId = props.id!;
		if (dataframeId != 0) {
			Dataframe.DeleteDataframe(dataframeId)
				.then((_response) => {
					closeDeleteModal();

					// if (response.success) {
					// 	props.refreshList && props.refreshList();
					// }
					props.refreshList && props.refreshList();
				})
				.catch(() => {
					addToast(
						`Cannot delete dataframe "${props.title}", it is linked to one or more charts`,
						AlertVariant.danger
					);
				});
		}
	};

	const DeleteDashboard = () => {
		const dashboardId = props.id!;
		if (dashboardId != 0) {
			Dashboard.Delete(dashboardId)
				.then((_response) => {
					closeDeleteModal();

					// if (response.success) {
					// 	props.refreshList && props.refreshList();
					// }
					props.refreshList && props.refreshList();
					addToast('Dashboard deleted succesfully', AlertVariant.success);
				})
				.catch(() => {
					addToast('An error occured while Deleting the Dashboard', AlertVariant.danger);
				});
		}
	};

	const DeleteReport = () => {
		const reportId = props.id!;
		if (reportId != 0) {
			Report.Delete(reportId)
				.then((_response) => {
					closeDeleteModal();
					props.refreshList && props.refreshList();
				})
				.catch(() => {
					addToast('An error occured while Deleting the Report', AlertVariant.danger);
				});
		}
	};

	const DeleteItem = () => {
		switch (props.type) {
			case CardTypesEnum.Chart:
				DeleteChart();
				break;
			case CardTypesEnum.Dashboard:
				DeleteDashboard();
				break;
			case CardTypesEnum.DataFrame:
				DeleteDataframe();
				break;
			case CardTypesEnum.Folders:
				DeleteFolder();
				break;
			case CardTypesEnum.Report:
				DeleteReport();
				break;
		}
	};

	const RecordFolderId = () => {
		setUrl(props.url);

		setTimeout(() => {
			const url = window.location.href;
			const pathname = window.location.pathname;
			const segments = url.split('/');

			if (segments.indexOf('folder') > -1) {
				const lastSegment = segments[segments.length - 1];
				const folderId = lastSegment;

				localStorage.setItem('currentSelectedFolderId', folderId);

				if (url.includes('/analyze')) {
					localStorage.setItem('prevAnalyzePage', pathname.replace('/analyze/', ''));
				} else if (url.includes('/report')) {
					localStorage.setItem('prevReportPage', pathname.replace('/report/', ''));
				} else if (url.includes('/present')) {
					localStorage.setItem('prevPresentPage', pathname.replace('/present/', ''));
				} else if (url.includes('/analyze/folder') || url.includes('/analyze/dashboards')) {
					localStorage.setItem('prevDashboardPage', pathname.replace('/analyze/', ''));
				}
			}
			if (pathname.includes('/analyze/charts')) {
				localStorage.setItem('currentNestedFolderPath', pathname);
			}
		}, 50);
	};

	useEffect(() => {
		setUrl(props.url);
	});

	const onToggle = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const onFocus = () => {
		const element = document.getElementById('toggle-kebab');

		if (element) element.focus();
	};

	const onSelect = () => {
		setIsOpen(false);
		onFocus();
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (event.key === 'Enter' || event.keyCode === 13) {
			onToggle(true);
		}
	};

	const actionsHtml = () => {
		const dropdownItems: JSX.Element[] = [];
		normalizedActions.map((action, index) => {
			dropdownItems.push(
				<DropdownItem
					key={index}
					tabIndex={index}
					component="button"
					role="menuitem"
					onClick={(e) => action.callback(e)}
				>
					{action.name}
				</DropdownItem>
			);
		});

		return (
			<Dropdown
				onSelect={onSelect}
				toggle={
					<KebabToggle
						id="toggle-kebab"
						onToggle={onToggle}
						onKeyDown={handleKeyDown}
					/>
				}
				isOpen={isOpen}
				isPlain
				dropdownItems={dropdownItems}
			/>
		);
	};

	const getFolderEnumType = () => {
		const url = window.location.pathname;
		const segments = url.split('/');
		const secondSegment = segments[2];

		return secondSegment as FolderTypesEnum;
	};

	const addFolderModalProps: AddFoldersModalProps = {
		isOpen: isFolderModalOpen,
		folderHeadingTitle: '',
		folderId: props.id,
		folderName: props.title,
		folderType: getFolderEnumType(),
		onClose: handleFolderModalToggle,
		onSetSubSide: () => ({}),
		mustNotRedirect: true,
		successNotification: 'Updated Folder Successfully',
		refreshList: props.refreshList,
	};

	return (
		<React.Fragment>
			<Link
				to={toUrl}
				onClick={RecordFolderId}
				className="ziCard"
				data-testid={`zi-card-${props.title}`}
				onMouseEnter={() => handleMouseEnter(false)}
				onMouseLeave={handleMouseLeave}
				onFocus={() => handleMouseEnter(true)}
			>
				<span>{props.title}</span>
				<div className="footer">
					<Flex direction={{ default: 'row' }}>
						{(props.isClustered == false || !props.isClustered) && (
							<span className="fa-stack fa-1x">
								<FontAwesomeIcon
									icon={props.icon}
									size="2x"
									className="non-stacked-icon"
									style={{
										color: props.icon === faFolderBlank ? '#6a6e73' : 'black',
									}}
								/>
								{props.isShared && (
									<Tooltip content={`Shared by: ${props.ownerName ?? 'none'}`}>
										<FontAwesomeIcon
											icon={faCircleUser}
											size="1x"
											className="fa-stack-1x fa-cx share-icon"
										/>
									</Tooltip>
								)}
							</span>
						)}
						{props.isClustered && (
							<span className="fa-stack fa-2x">
								<FontAwesomeIcon
									icon={props.icon}
									style={{
										color: props.icon === faFolderBlank ? '#6a6e73' : 'black',
									}}
									className="stacked-icon"
								/>
								<FontAwesomeIcon
									icon={faCube}
									size="1x"
									className="fa-stack-1x fa-cx stacked-icon"
								/>
							</span>
						)}
					</Flex>

					{props.isNew ? <Badge>New</Badge> : null}

					{isHovered &&
						hasMinimumPermissions &&
						(!props.hideEllipsis || props.hideEllipsis === undefined) && (
							<div
								onClick={(e) => {
									setUrl('');
									e.stopPropagation();
									e.preventDefault();
								}}
							>
								{actionsHtml()}
							</div>
						)}
				</div>
			</Link>
			<DeleteConfirmationModal
				isOpen={isDeleteFolderModalOpen}
				onClose={closeDeleteModal}
				onSubmit={DeleteItem}
			/>
			<AddFoldersModal {...addFolderModalProps} />
		</React.Fragment>
	);
}

export default ZiCard;
