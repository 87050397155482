import React, { Dispatch, SyntheticEvent, useEffect, useState } from 'react';
import { IConfig } from '../../../layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	faCalendar,
	faChartLineUp,
	faChartPie,
	faCopy,
	faDashboard,
	faDatabase,
	faFolder,
	faPage,
	faTable,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, DropdownToggle } from '@patternfly/react-core';
import { AddNewDropdownTypes } from '../../../types/add-new-dropdown/add-new-dropdown-types';

import './AddNewDropdown.scss';
import ZiChartOptionsModal, {
	ZiChartOptionsModalProps,
} from '../../modals/charts/ZiChartOptionsModal';
import AddFoldersModal, { AddFoldersModalProps } from '../../modals/folders/AddFoldersModal';
import ZiDashboardOptionsModal, {
	ZiDashboardOptionsModalProps,
} from '../../modals/dashboard/ZiDashboardOptionsModal';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import ZiCreateSelectionModal, {
	CopyTypesEnum,
	ViewTypesEnum,
	ZiCreateSelectionOptionsModalProps,
} from '../../modals/shared/creation-selection/ZiCreateSelectionModal';
import { useIsGranted, useUser } from '../../../components/user/ApplicationProvider';
import { Permission } from '../../../enums/permission.enum';
import { SharedEntity } from '../../../api/shared-entity/SharedEntity';
import { SharedEntityUser, TSharedEntityUser } from '../../../api/shared-entity/SharedEntityUser';

export type AddNewDropdownProps = {
	folderHeadingTitle: string;
	folderType?: string;
	onSetSubSide: Dispatch<React.SetStateAction<IConfig>>;
};

function AddNewDropdown(props: AddNewDropdownProps) {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isFolderModalOpen, setIsFolderModalOpen] = useState<boolean>(false);
	const [isAddChartModalOpen, setIsAddChartModalOpen] = useState(false);
	const [isAddDashboardModalOpen, setIsAddDashboardModalOpen] = useState<boolean>(false);
	const [isAddReportModalOpen, setIsAddReportModalOpen] = useState<boolean>(false);
	const segments = useLocation().pathname.split('/'); // Split the URL by '/'
	const isGranted = useIsGranted();
	const hasAddChartPerm = isGranted(Permission.CreateChart);
	const hasAddDataframePerm = isGranted(Permission.CreateDataframe);
	const hasAddDashboardPerm = isGranted(Permission.CreateDashboard);
	const hasAddFolderPerm = isGranted(Permission.CreateFolder);
	const hasAddReportPerm = isGranted(Permission.CreateReport);
	const hasAddSchedulePerm = isGranted(Permission.CreateSchedule);
	const hasAddTablePerm = isGranted(Permission.CreateTable);
	const hasNoAddPerms =
		!hasAddChartPerm &&
		!hasAddDataframePerm &&
		!hasAddDashboardPerm &&
		!hasAddFolderPerm &&
		!hasAddReportPerm &&
		!hasAddSchedulePerm &&
		!hasAddTablePerm;
	const [sharedPermission, setSharedPermission] = useState<TSharedEntityUser>(
		SharedEntityUser.Default()
	);
	const currentUser = useUser();
	const folderIdFromLocalStorage = localStorage.getItem('currentSelectedFolderId');
	useEffect(() => {
		if (folderIdFromLocalStorage && !isNaN(parseInt(folderIdFromLocalStorage))) {
			SharedEntity.GetPermissionForEntity(
				'folder',
				parseInt(folderIdFromLocalStorage),
				currentUser.id
			)
				.then((sharedPermissionResponse) => {
					setSharedPermission(sharedPermissionResponse);
				})
				.catch(() => {
					console.log('Failed getting Shared Permisison for user');
				});
		} else {
			// Default to yes as it is the base folder
			setSharedPermission({ can_edit: true, can_share: true });
		}
	}, [folderIdFromLocalStorage]);

	const onSelect = (e?: SyntheticEvent<HTMLDivElement, Event>) => {
		setIsOpen(false);
		const target = e?.currentTarget as HTMLDivElement;
		const id = target.id;
		switch (id) {
			case AddNewDropdownTypes.Chart:
				handleChartModalToggle();
				break;
			case AddNewDropdownTypes.Dashboard:
				handleDashboardModalToggle();
				break;
			case AddNewDropdownTypes.Dataframe:
				navigate('dataframes/create');
				break;
			case AddNewDropdownTypes.Folder:
				handleFolderModalToggle();
				break;
			case AddNewDropdownTypes.Report:
				handleReportModalToggle();
				break;
			case AddNewDropdownTypes.Table:
				//todo
				break;
		}
	};

	const handleFolderModalToggle = () => {
		setIsFolderModalOpen(!isFolderModalOpen);
		setIsOpen(false);
	};

	const handleChartModalToggle = () => {
		setIsAddChartModalOpen((prev) => !prev);
		setIsOpen(false);
	};

	const handleDashboardModalToggle = () => {
		setIsAddDashboardModalOpen((prev) => !prev);
		setIsOpen(false);
	};

	const handleReportModalToggle = () => {
		setIsAddReportModalOpen((prev) => !prev);
		setIsOpen(false);
	};

	const chartOptionsModalProps: ZiChartOptionsModalProps = {
		isOpen: isAddChartModalOpen,
		onClose: handleChartModalToggle,
	};

	const dashboardOptionsModalProps: ZiDashboardOptionsModalProps = {
		isOpen: isAddDashboardModalOpen,
		onClose: handleDashboardModalToggle,
	};

	const addReportModalProps: ZiCreateSelectionOptionsModalProps = {
		isOpen: isAddReportModalOpen,
		onClose: handleReportModalToggle,
		copyType: CopyTypesEnum.Report,
		defaultView: ViewTypesEnum.Default,
		title: 'Add Report',
		urlBase: '/report/create',
		viewOptions: [
			{
				type: ViewTypesEnum.Measure,
				icon: faChartLineUp,
				label: 'Key Measures',
			},
			{
				type: ViewTypesEnum.Dataframe,
				icon: faDatabase,
				label: 'Dataframe',
			},
			// {
			// 	type: ViewTypesEnum.Template,
			// 	icon: faThLarge,
			// 	label: 'Templates',
			// },
			{
				type: ViewTypesEnum.Copy,
				icon: faCopy,
				label: 'Copy Existing',
				tooltip: 'Coming Soon',
			},
		],
	};

	const addFolderModalProps: AddFoldersModalProps = {
		isOpen: isFolderModalOpen,
		folderHeadingTitle: props.folderHeadingTitle,
		folderType: props.folderType,
		onClose: handleFolderModalToggle,
		onSetSubSide: props.onSetSubSide,
		successNotification: 'Created Folder Successfully',
	};
	const dropdownData: JSX.Element[] = [];
	switch (segments[1]) {
		case 'analyze':
			if (hasAddChartPerm && segments.includes('charts')) {
				dropdownData.push(
					<DropdownItem
						id={AddNewDropdownTypes.Chart}
						key={AddNewDropdownTypes.Chart}
					>
						<FontAwesomeIcon icon={faChartPie} /> Chart
					</DropdownItem>
				);
			}
			if (hasAddDataframePerm && segments.includes('dataframes')) {
				dropdownData.push(
					<DropdownItem
						id={AddNewDropdownTypes.Dataframe}
						key={AddNewDropdownTypes.Dataframe}
					>
						<FontAwesomeIcon icon={faDatabase} /> Dataframe
					</DropdownItem>
				);
			}
			if (hasAddTablePerm) {
				dropdownData.push(
					<DropdownItem
						id={AddNewDropdownTypes.Table}
						key={AddNewDropdownTypes.Table}
					>
						<FontAwesomeIcon icon={faTable} /> Table
					</DropdownItem>
				);
			}
			if (
				hasAddDashboardPerm &&
				!segments.includes('charts') &&
				!segments.includes('dataframes') &&
				!segments.includes('tables')
			) {
				dropdownData.push(
					<DropdownItem
						id={AddNewDropdownTypes.Dashboard}
						key={AddNewDropdownTypes.Dashboard}
					>
						<FontAwesomeIcon icon={faDashboard} /> Dashboard
					</DropdownItem>
				);
			}
			break;
		case 'report':
			if (hasAddReportPerm) {
				dropdownData.push(
					<DropdownItem
						id={AddNewDropdownTypes.Report}
						key={AddNewDropdownTypes.Report}
					>
						<FontAwesomeIcon icon={faPage} /> Report
					</DropdownItem>
				);
			}
			if (hasAddSchedulePerm) {
				dropdownData.push(
					<DropdownItem
						id={AddNewDropdownTypes.Schedule}
						key={AddNewDropdownTypes.Schedule}
					>
						<FontAwesomeIcon icon={faCalendar} /> Schedule
					</DropdownItem>
				);
			}
			break;
		default:
			break;
	}

	if (
		!segments.includes(FolderTypesEnum.Queue) &&
		!segments.includes(FolderTypesEnum.Recents) &&
		!segments.includes(FolderTypesEnum.Schedules) &&
		hasAddFolderPerm
	) {
		dropdownData.push(
			<DropdownItem
				id={AddNewDropdownTypes.Folder}
				key={AddNewDropdownTypes.Folder}
			>
				<FontAwesomeIcon icon={faFolder} /> Folder
			</DropdownItem>
		);
	}

	if (hasNoAddPerms || !sharedPermission.can_edit) {
		return <></>;
	}

	return (
		<>
			<div className="dv-dropdown-container">
				<Dropdown
					toggle={
						<DropdownToggle onToggle={() => setIsOpen(!isOpen)}>Add </DropdownToggle>
					}
					isOpen={isOpen}
					onSelect={onSelect}
					dropdownItems={dropdownData}
					className="pull-right btn-dropdown-container add-new-dropdown"
				></Dropdown>
			</div>

			<ZiChartOptionsModal {...chartOptionsModalProps} />
			<ZiDashboardOptionsModal {...dashboardOptionsModalProps} />
			<ZiCreateSelectionModal {...addReportModalProps} />
			<AddFoldersModal {...addFolderModalProps} />
		</>
	);
}

export default AddNewDropdown;
