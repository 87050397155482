import { Button, Form, FormGroup, TextInput } from '@patternfly/react-core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { useMount } from 'react-use';
import { TDimension } from '../../../api/analytics/Dimension';
import { TFolder } from '../../../api/foundational-elements/Folder';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeViewSelect from '../../../components/form/Select/TreeViewSelect';
import EntityMiscButtons from '../../../helpers/helper-components/EntityMiscButtons';
import { MultipartResponse } from '../../../helpers/multipart-response.helper';
import { DataframeDataRetrievalResponse, TDataframe } from '../../../api/types';
import DatePeriodSelector from '../../../components/date-period-selector/DatePeriodSelector';
import { faFolder, faPlus } from '@fortawesome/pro-solid-svg-icons';
import AddFilterModal from './AddFilterModal';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { uniq } from 'lodash';
import { TDimensionAttribute } from '../../../api/analytics/DimensionAttribute';
import _ from 'lodash';
import { generateGUID } from '../../../helpers/guid.helper';
import {
	populateDataForDimension,
	populateDataForDimensionAttribute,
} from '../../../hooks/DataBuilderHooks';
import { DataBuilderZones } from '../../../enums/data-builder-zones';
import { faTimes } from '@fortawesome/sharp-solid-svg-icons';
import FilterModal from '../../../components/modals/databuilder/FilterModal';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

type Props = {
	name?: string;
	folders: TFolder[];
	folder?: TFolder;
	dimensions: TDimension[];
	onRunReport: () => void;
	parameters: DraggableMenuItemData[];
	setParameters: Dispatch<SetStateAction<DraggableMenuItemData[]>>;
	setSelectedDate: Dispatch<SetStateAction<TNewDateRange | undefined>>;
	previewData?: MultipartResponse<DataframeDataRetrievalResponse>;
	dataframe: TDataframe;
};

const ViewReport = (props: Props) => {
	const {
		name,
		folders,
		folder,
		dimensions,
		onRunReport,
		parameters,
		setParameters,
		setSelectedDate,
		previewData,
		dataframe,
	} = props;

	const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState<boolean>(false);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
	const [availableDimensions, setAvailableDimensions] = useState<TDimension[]>([]);
	const [activeFilter, setActiveFilter] = useState<DraggableMenuItemData>();

	useMount(() => {
		const dimsToUse: TDimension[] = [];
		let dimensionIds: number[] = [];
		let dimensionAttrIds: number[] = [];

		dimensionIds = [
			...dataframe.rowEntry
				.filter((row) => {
					return row.entity_type == OptionsBuilderItemTypes.Dimension;
				})
				.map((dim) => dim.entity_id),
			...dataframe.columnEntry
				.filter((col) => {
					return col.entity_type == OptionsBuilderItemTypes.Dimension;
				})
				.map((dim) => dim.entity_id),
			...dataframe.filters
				.filter((filter) => {
					return filter.entity_type == OptionsBuilderItemTypes.Dimension;
				})
				.map((dim) => dim.entity_id),
		];

		dimensionAttrIds = [
			...dataframe.rowEntry
				.filter((row) => {
					return row.entity_type == OptionsBuilderItemTypes.DimensionAttribute;
				})
				.map((dim) => dim.entity_id),
			...dataframe.columnEntry
				.filter((col) => {
					return col.entity_type == OptionsBuilderItemTypes.DimensionAttribute;
				})
				.map((dim) => dim.entity_id),
			...dataframe.filters
				.filter((filter) => {
					return filter.entity_type == OptionsBuilderItemTypes.DimensionAttribute;
				})
				.map((dim) => dim.entity_id),
		];

		// Remove duplicate Ids
		dimensionIds = uniq(dimensionIds);
		dimensionAttrIds = uniq(dimensionAttrIds);

		props.dimensions.forEach((dimension: TDimension): void => {
			const clonedDim = _.cloneDeep(dimension);

			if (
				dimensionIds.includes(clonedDim.id) ||
				clonedDim.dimensionAttributes.some((attr: TDimensionAttribute) =>
					dimensionAttrIds.includes(attr.id)
				)
			) {
				clonedDim.dimensionAttributes = clonedDim.dimensionAttributes.filter(
					(attr: TDimensionAttribute) => dimensionAttrIds?.includes(attr.id)
				);

				dimsToUse.push(clonedDim);
			}
		});

		setAvailableDimensions(dimsToUse);
	});

	const handleAddParameterFilter = (entityId: number, entityType: string) => {
		const exists = parameters.find(
			(param) => param.entityType === entityType && entityId === param.data?.id
		);

		if (!exists) {
			if (entityType === OptionsBuilderItemTypes.Dimension) {
				const dimension = dimensions.find((dim) => dim.id === entityId);

				if (dimension) {
					const dimData: DraggableMenuItemData = {
						id: generateGUID(),
						entity: dimension,
						data: populateDataForDimension(dimension),
						entityType: OptionsBuilderItemTypes.Dimension,
						itemType: 'dropdown',
						allowedZones: [DataBuilderZones.filters],
						static: false,
						icon: 'dataSource',
					};

					if (dimData.data) {
						dimData.data.isParameter = true;
					}

					setParameters([...parameters, ...[dimData]]);
				}
			} else {
				const dimension = dimensions.find((dim) =>
					dim.dimensionAttributes.some((dimAttr) => dimAttr.id === entityId)
				);
				const dimAttribute = dimension?.dimensionAttributes.find(
					(dimAttr) => dimAttr.id === entityId
				);

				if (dimAttribute) {
					const dimData: DraggableMenuItemData = {
						id: generateGUID(),
						entity: dimAttribute,
						data: populateDataForDimensionAttribute(dimAttribute),
						entityType: OptionsBuilderItemTypes.DimensionAttribute,
						itemType: 'dropdown',
						allowedZones: [DataBuilderZones.filters],
						static: false,
						icon: 'dataSource',
					};

					if (dimData.data) {
						dimData.data.isParameter = true;
					}

					setParameters([...parameters, ...[dimData]]);
				}
			}
		}
	};

	const handleFilterModalOpen = (param: DraggableMenuItemData) => {
		setActiveFilter(param);
		setIsFilterModalOpen(true);
	};

	const handleRemoveFilter = (newParam: DraggableMenuItemData) => {
		setParameters(
			parameters.filter(
				(param) =>
					!(
						param.entityType === newParam.entityType &&
						param.data?.id === newParam.data?.id
					)
			)
		);
	};

	const handleFilterModalSave = (newParam: DraggableMenuItemData) => {
		setParameters(
			parameters.map((param) => {
				if (
					param.entityType === newParam.entityType &&
					param.data?.id === newParam.data?.id
				) {
					if (param.data) {
						param.data.showSetFilterIcon = true;
					}
					param = newParam;
				}
				return param;
			})
		);
		setIsFilterModalOpen(false);
	};

	const handleFilterModalClose = () => {
		setIsFilterModalOpen(false);
	};

	const getDimensionsForFilter = () => {
		const dims: TDimension[] = [];

		dimensions.map((series: TDimension) => {
			const clone = _.cloneDeep(series);
			const newDimAttribute: TDimensionAttribute = {
				code: clone.code,
				creator: clone.creator,
				created_at: clone.created_at,
				name: clone.name,
				id: clone.id,
				show_on_summary: clone.show_on_summary,
				type: 'Dimension',
				dimension: clone,
				disabled: false,
				display_attribute: true,
				effective_dated: false,
				sequence: 0,
			};

			clone.dimensionAttributes.forEach((dimAttr) => {
				dimAttr.type = OptionsBuilderItemTypes.DimensionAttribute;
			});

			clone.dimensionAttributes.push(newDimAttribute);
			dims.push(clone);
		});

		return dims;
	};

	return (
		<div className={'view-report-container'}>
			<div className={'details-container'}>
				<div className={'details'}>
					<div className={'form-container'}>
						<Form>
							<FormGroup
								label={'Report Name'}
								type="text"
								fieldId="name"
							>
								<TextInput
									type="text"
									value={name}
									isDisabled={true}
								/>
							</FormGroup>
							<FormGroup
								label={<FontAwesomeIcon icon={faFolder} />}
								type="text"
								fieldId="folder"
								className="folder-container"
							>
								<TreeViewSelect
									data={
										folders
											? folders.map((_: TFolder) =>
													BuildTreeViewItem(_, _.items)
											  )
											: []
									}
									placeHolderText={folder ? folder.name : 'No Selected Folder'}
									isDisabled={true}
								/>
							</FormGroup>
						</Form>
					</div>
					<div className={'actions-container'}>
						<Button
							variant="primary"
							onClick={onRunReport}
						>
							Run Report
						</Button>
					</div>
				</div>
			</div>
			<div className={'misc-actions'}>
				<EntityMiscButtons
					entityType={'Report'}
					entityName={name ?? ''}
					canShare={false}
					exportData={previewData}
					limitReached={previewData?.json?.result_size?.limitReached}
				/>
			</div>
			<div className={'filters'}>
				<Form isHorizontal>
					<FormGroup
						label="Filters"
						isInline
					>
						<div className="date-selector-container">
							<DatePeriodSelector applyDateRange={setSelectedDate} />
						</div>

						{parameters.map((param) => {
							return (
								<>
									<div className={`custom-button-chip`}>
										<div
											className="label"
											onClick={() => {
												handleFilterModalOpen(param);
											}}
										>
											{param.data?.title}
										</div>
										{param.data?.showSetFilterIcon && (
											<>
												<FontAwesomeIcon icon={faFilter} />
												<>&nbsp;</>
											</>
										)}
										{param.data?.isParameter && (
											<div className="remove-btn">
												<Button
													variant="plain"
													onClick={() => {
														handleRemoveFilter(param);
													}}
												>
													<FontAwesomeIcon icon={faTimes} />
												</Button>
											</div>
										)}
									</div>
								</>
							);
						})}

						<div className="add-filter">
							<Button
								className="btn-add-filter"
								icon={<FontAwesomeIcon icon={faPlus} />}
								onClick={() => {
									setIsAddFilterModalOpen(true);
								}}
							>
								<strong>Add Filter</strong>
							</Button>
						</div>
					</FormGroup>
				</Form>
			</div>
			<AddFilterModal
				setModalOpen={setIsAddFilterModalOpen}
				isOpen={isAddFilterModalOpen}
				addReportFilter={handleAddParameterFilter}
				dimensions={getDimensionsForFilter()}
				selectedIds={parameters.map((f) => f.data?.id ?? 0)}
			/>
			{isFilterModalOpen && (
				<FilterModal
					filter={activeFilter ?? null}
					handleClose={handleFilterModalClose}
					handleSave={handleFilterModalSave}
					readonly={activeFilter?.data?.disabled}
				/>
			)}
		</div>
	);
};

export default ViewReport;
