import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import './DataBuilderDropGrid.scss';
import { DataBuilderZones } from '../../../enums/data-builder-zones';
import {
	AlertVariant,
	Button,
	Form,
	FormGroup,
	Grid,
	GridItem,
	Modal,
	ModalBoxFooter,
	ModalVariant,
	Radio,
	Spinner,
	Text,
	TextContent,
	TextVariants,
	Tooltip,
	TreeViewDataItem,
} from '@patternfly/react-core';
import DataBuilderGridItem from './DataBuilderGridItem';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import {
	buildDataframeRequest,
	useDimensionOptions,
	useFactOptions,
} from '../../../hooks/DataBuilderHooks';
import {
	GenericKeyValueResponse,
	TDataframe,
	TDataframeData,
	TDateRange,
} from '../../../api/types';
import DragIcon from '@patternfly/react-icons/dist/esm/icons/pficon-dragdrop-icon';
import { DragDropContext, Draggable, DraggableLocation, Droppable } from 'react-beautiful-dnd';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { useParams } from 'react-router';
import { DataBuilderTypes } from '../../../enums/data-builder-types';
import { DataBuilderDropGridTypes } from '../../../enums/data-builder-drop-grid-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsH } from '@fortawesome/pro-regular-svg-icons';
import DualListSelectorGeneric from '../../../helpers/helper-components/DualListSelectorGeneric';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import SearchableTreeViewSelect from '../../form/Select/SearchableTreeViewSelect';
import { KeyMeasureFact } from '../../../api/analytics/KeyMeasureFact';
import { KeyMeasure, TKeyMeasure } from '../../../api/analytics/KeyMeasure';
import { Dimension } from '../../../api/analytics/Dimension';
import {
	DataframeDataJoin,
	TDataframeDataExistingJoin,
	TDataframeDataJoin,
} from '../../../api/dataframe-data-join/DataframesDataJoin';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { Dataframe, TNewDataframe, TNewDataframeOrder } from '../../../api/dataframes/Dataframes';
import { TFolder } from '../../../api/types/folder';
import { useNavigate } from 'react-router';

type Zone = {
	label: string;
	zoneType: DataBuilderZones;
	zoneItems: DraggableMenuItemData[];
	hasBreakoutGroups: boolean;
};

type Props = {
	builderType: DataBuilderTypes;
	dropGridType: DataBuilderDropGridTypes;
	zones: Array<Zone>;
	zoneItemAction?: (key: string, zoneItem: DraggableMenuItemData, zone: DataBuilderZones) => void;
	zoneItemDateSelect?: (startDateRange: TDateRange, endDateRange: TDateRange) => void;
	zonesToHighlight: DataBuilderZones[];
	updateZoneItem?: (
		zoneItem: DraggableMenuItemData,
		destinationZone: DataBuilderZones,
		sourceZone?: DataBuilderZones
	) => void;
	updateAllZoneItems?: (zoneItems: DraggableMenuItemData[], zone: DataBuilderZones) => void;
	updateZonesToHighlight: () => void;
	dataframeDatasets?: TDataframeData[] | undefined;
	savedDataframeDataJoins?: TDataframeDataExistingJoin[];
	setSavedDataframeDataJoins?: Dispatch<SetStateAction<TDataframeDataExistingJoin[]>>;
	setDeleteDataframeDataJoinIds?: Dispatch<SetStateAction<number[]>>;
	fetchDataframeDataJoins?: () => void;
	dataframe?: TDataframe;
	facts?: DraggableMenuItemData[];
	rows?: DraggableMenuItemData[];
	filters?: DraggableMenuItemData[];
	name?: string;
	order?: TNewDataframeOrder | undefined;
	folder?: TFolder;
	setDataframeId?: Dispatch<React.SetStateAction<number | undefined>>;
	setDisablePreviewBtn?: Dispatch<React.SetStateAction<boolean>>;
	disableCalculatedFact?: boolean;
	setHasInvalidJoins?: Dispatch<React.SetStateAction<boolean>>;
	isLoadingKMs?: boolean;
	setIsLoadingKMs?: Dispatch<React.SetStateAction<boolean>>;
	setMinimumOneInvalidJoin?: Dispatch<React.SetStateAction<boolean>>;
	datePeriodSelectorLabel?: string;
	datePeriodSelectorDisabledMessage?: string;
	hideConfigureCalculationOption?: boolean;
	hideKeyMeasureJoinZone?: boolean;
};

const DataBuilderDropGrid = (props: Props) => {
	const {
		builderType,
		dropGridType,
		zones,
		zonesToHighlight,
		zoneItemAction,
		zoneItemDateSelect,
		updateZoneItem,
		updateAllZoneItems,
		updateZonesToHighlight,
		dataframeDatasets,
		savedDataframeDataJoins,
		setSavedDataframeDataJoins,
		setDeleteDataframeDataJoinIds,
		fetchDataframeDataJoins,
		isLoadingKMs,
		setIsLoadingKMs,
		setMinimumOneInvalidJoin,
		datePeriodSelectorLabel,
		datePeriodSelectorDisabledMessage,
	} = props;

	const { frameId, chartId, reportId } = useParams();
	const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [avaliableMeasures, setAvaliableMeasures] = React.useState<TreeViewDataItem[]>([]);
	const [hasChosenLeftKM, setHasChosenLeftKM] = React.useState<boolean>(false);
	const [hasChosenRightKM, setHasChosenRightKM] = React.useState<boolean>(false);
	const [selectedKeyMeasureIDs, setSelectedKeyMeasureIDs] = React.useState<number[]>([]);
	const [triggerKmSelction, settriggerKmSelction] = React.useState<boolean>(false);
	const [triggerKmDropdownChange, setTriggerKmDropdownChange] = React.useState<boolean>(false);
	const [selectedLeftKeyMeasureDims, setSelectedLeftKeyMeasureDims] = React.useState<number[]>(
		[]
	);
	const [selectedRightKeyMeasureDims, setSelectedRightKeyMeasureDims] = React.useState<number[]>(
		[]
	);
	const [commonDimensions, setCommonDimensions] = React.useState<React.ReactNode[]>([]);
	const [chosenCommonDimensions, setChosenCommonDimensions] = React.useState<React.ReactNode[]>(
		[]
	);
	const chosenPaneRef = useRef<HTMLDivElement>(null);
	const [leftKmId, setLeftKmId] = React.useState<number>(0);
	const [rightKmId, setRightKmId] = React.useState<number>(0);
	const [leftKmName, setLeftKmName] = React.useState<string>();
	const [rightKmName, setRightKmName] = React.useState<string>();
	const [joinType, setJoinType] = React.useState<string>('inner');
	const [keyMeasureNames, setkeyMeasureNames] = React.useState<string[]>([]);
	const [chosenKeyMeasures, setChosenKeyMeasures] = React.useState<
		GenericKeyValueResponse<string>[]
	>([]);
	const [hasSetDroppedFacts, setHasSetDroppedFacts] = React.useState<boolean>(false);
	const [kmDimensions, setKmDimensions] = React.useState<GenericKeyValueResponse<number[]>[]>([]);
	const [existingJoinDimensionIds, setExistingJoinDimensionIds] = React.useState<number[]>([]);
	const [currentSelectedDataframeDataJoinId, setcurrentSelectedDataframeDataJoinId] =
		React.useState<number>();
	const { addToast } = useToast();
	const navigate = useNavigate();

	const onZoneItemSelect = (key: string, data: DraggableMenuItemData, zoneType: string): void => {
		zoneItemAction && zoneItemAction(key, data, zoneType as DataBuilderZones);

		if (key == 'remove_fact') {
			const kmfId = data.data?.id;

			if (kmfId) {
				setIsLoadingKMs && setIsLoadingKMs(true);
				void KeyMeasureFact.Get(kmfId, ['keyMeasure', 'unitType'])
					.then((response) => {
						const km = response.keyMeasure as TKeyMeasure;
						const kmName = km.display_name ?? km.name;

						if (savedDataframeDataJoins) {
							const updatedDataframeDataJoins = savedDataframeDataJoins?.filter(
								(x) => x.key_measure_from != km.id && x.key_measure_to != km.id
							);
							const deleteDataframeDataJoins = savedDataframeDataJoins?.filter(
								(x) => x.key_measure_from == km.id || x.key_measure_to == km.id
							);
							const deleteDataframeDataJoinIds = deleteDataframeDataJoins.map(
								(x) => x.id!
							);

							if (deleteDataframeDataJoinIds && setDeleteDataframeDataJoinIds) {
								setDeleteDataframeDataJoinIds(deleteDataframeDataJoinIds);
							}
							if (updatedDataframeDataJoins && setSavedDataframeDataJoins) {
								setSavedDataframeDataJoins(updatedDataframeDataJoins);
							}
						}

						const updatedKmNames = keyMeasureNames.filter((x) => x != kmName);
						const updatedKms = chosenKeyMeasures.filter((x) => x.value != kmName);
						setkeyMeasureNames(updatedKmNames);
						setChosenKeyMeasures(updatedKms);
						setChosenCommonDimensions([]);
						settriggerKmSelction(!triggerKmSelction);

						setTimeout(() => {
							setIsLoadingKMs && setIsLoadingKMs(false);
						}, 1200);
					})
					.finally(() => {
						setIsLoadingKMs && setIsLoadingKMs(false);
					});
			}
		}
	};

	const getZoneItemOptions = (
		gridItem: DraggableMenuItemData,
		zoneType: DataBuilderZones,
		hasBreakoutGroups: boolean
	) => {
		if (
			zoneType === DataBuilderZones.facts ||
			zoneType === DataBuilderZones.rows ||
			zoneType === DataBuilderZones.columns
		) {
			switch (gridItem.entityType) {
				case OptionsBuilderItemTypes.KeyMeasureFact:
					return useFactOptions(
						gridItem.data?.id == undefined || gridItem.data?.calculated,
						props.disableCalculatedFact,
						gridItem.data,
						props.hideConfigureCalculationOption,
						props.builderType == DataBuilderTypes.report && reportId !== undefined
					);
				case OptionsBuilderItemTypes.Dimension:
					return useDimensionOptions(
						gridItem,
						zoneType === DataBuilderZones.columns ? false : hasBreakoutGroups
					);
				case OptionsBuilderItemTypes.DimensionAttribute:
					return useDimensionOptions(
						gridItem,
						zoneType === DataBuilderZones.columns ? false : hasBreakoutGroups
					);
				case OptionsBuilderItemTypes.DateSeries:
					return useDimensionOptions();
			}
		}

		return [];
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDrop = (_e: React.DragEvent<HTMLDivElement>, zoneType: DataBuilderZones) => {
		let gridItem: DraggableMenuItemData;
		if (_e.dataTransfer) {
			gridItem = JSON.parse(
				_e.dataTransfer.getData('dataBuilderGridItem')
			) as DraggableMenuItemData;
			if (zoneType === DataBuilderZones.filters) {
				gridItem.itemType = 'button';
			} else if (zoneType === DataBuilderZones.drills) {
				gridItem.icon = 'vertical-grip';
				gridItem.itemType = 'icon-text';
				gridItem.allowedZones = [DataBuilderZones.drills];
			} else if (gridItem.itemType === 'button') {
				gridItem.itemType = 'dropdown';
			}
			updateZoneItem && updateZoneItem(gridItem, zoneType);
			updateZonesToHighlight();

			setHasSetDroppedFacts(false);
			setIsLoadingKMs && setIsLoadingKMs(true);
			setTimeout(() => {
				normalTemplate();
			}, 1000);
		}
	};

	const reorderZoneItems = (
		zoneItems: DraggableMenuItemData[],
		startIndex: number,
		endIndex: number
	) => {
		const result = Array.from(zoneItems);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = (
		source: DraggableLocation | null | undefined,
		destination: DraggableLocation | null | undefined
	) => {
		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source) {
			const sourceZone = zones.find((zone) => zone.zoneType === source?.droppableId);

			if (sourceZone) {
				const zoneItem: DraggableMenuItemData = sourceZone.zoneItems[source.index];

				if (
					source.droppableId === DataBuilderZones.filters &&
					destination.droppableId != DataBuilderZones.filters
				) {
					zoneItem.itemType = 'dropdown';
				} else if (
					source.droppableId != DataBuilderZones.filters &&
					destination.droppableId === DataBuilderZones.filters
				) {
					zoneItem.itemType = 'button';
				}
				updateZoneItem &&
					updateZoneItem(
						sourceZone.zoneItems[source.index],
						destination.droppableId as DataBuilderZones,
						source.droppableId as DataBuilderZones
					);

				if (source.droppableId === destination.droppableId) {
					const reorderedItems = reorderZoneItems(
						sourceZone.zoneItems,
						source.index,
						destination.index
					);

					updateAllZoneItems &&
						updateAllZoneItems(
							reorderedItems,
							destination.droppableId as DataBuilderZones
						);
				}
			}
		}
	};

	const getFormGroupTemplate = (
		zone: Zone,
		index: number,
		hideLabel: boolean,
		isVertical: boolean
	) => {
		const isFactsZone = zone.zoneType == DataBuilderZones.facts;

		if (isFactsZone && hasSetDroppedFacts == false) {
			const chosenKMFs = zone.zoneItems;

			const kmfIds = chosenKMFs
				.filter((x) => x.data?.id && x.data.id != undefined && !x.data?.calculated)
				.map((x) => x.data!.id) as number[];

			const promises = kmfIds.map((id) => KeyMeasureFact.Get(id, ['keyMeasure', 'unitType']));
			const chosenMeasures: GenericKeyValueResponse<string>[] = [];
			const kmNames: string[] = [];
			const kmDimList: GenericKeyValueResponse<number[]>[] = [];

			Promise.all(promises)
				.then((results) => {
					results.forEach((response) => {
						const km = response.keyMeasure as KeyMeasure;
						if (!chosenMeasures.find((x) => x.id == km.id)) {
							chosenMeasures.push({ id: km.id, value: km.display_name ?? km.name });

							const kmNameToUse = km.display_name ?? km.name;
							if (keyMeasureNames.includes(kmNameToUse) == false) {
								kmNames.push(kmNameToUse);
							}

							const newKmDim: GenericKeyValueResponse<number[]> = {
								id: km.id,
								value: km.dimensions as number[],
							};
							kmDimList.push(newKmDim);
						}
					});

					setKmDimensions(kmDimList);

					if (chosenMeasures.length > 0) {
						setChosenKeyMeasures(chosenMeasures);
					}
					setkeyMeasureNames([...keyMeasureNames, ...kmNames]);
					setHasSetDroppedFacts(true);
				})
				.catch((error) => {
					// Handle any errors here
					console.error('Error fetching KMF data:', error);
				})
				.finally(() => {
					setTimeout(() => {
						setIsLoadingKMs && setIsLoadingKMs(false);
					}, 1200);
				});
		}

		return (
			<FormGroup
				label={hideLabel ? undefined : zone.label}
				className={'data-builder-drop-grid-zone'}
				type="text"
			>
				<div
					onDragOver={(e) => {
						handleDragOver(e);
					}}
					onDrop={(e) => {
						handleDrop(e, zone.zoneType);
					}}
					className={`${
						zone.zoneType === DataBuilderZones.drills ? 'vertical-items-align' : ''
					}`}
				>
					<Droppable
						droppableId={`${zone.zoneType}`}
						key={`${index}_${zone.zoneType}`}
						direction={isVertical ? 'vertical' : 'horizontal'}
					>
						{(provided: any, snapshot: any) => (
							<div
								className={`draggable-wrapper ${isVertical ? 'flex-column' : ''} ${
									builderType != DataBuilderTypes.chart ? 'flex-wrap' : ''
								}`}
								ref={
									// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
									provided.innerRef
								}
								{
									// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
									...provided.droppableProps
								}
								style={{
									// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
									background: snapshot.isDraggingOver ? '#d2d2d2' : '',
								}}
							>
								{zone.zoneItems.map((zoneItem, zoneIndex) => {
									if (
										zone.zoneType === DataBuilderZones.drills &&
										zoneIndex === 0
									) {
										return (
											<div
												className={`data-builder-drop-grid-item-wrapper initial-vertical-item ${
													zoneItem.drilldownActive
														? 'vertical-item-active'
														: ''
												}`}
											>
												<div className={'data-builder-drop-grid-item'}>
													<div className={'data-builder-drop-grid-item'}>
														<DataBuilderGridItem
															icon={zoneItem.icon}
															name={
																zoneItem.data
																	? zoneItem.data.title
																	: ''
															}
															options={getZoneItemOptions(
																zoneItem,
																zone.zoneType,
																zone.hasBreakoutGroups
															)}
															handleSelect={(e: string): void => {
																onZoneItemSelect(
																	e,
																	zoneItem,
																	zone.zoneType
																);
															}}
															onDateSelect={zoneItemDateSelect}
															optionData={zoneItem}
															type={zoneItem.itemType}
															key={`data-builder-grid-item_${zone.zoneType}_${zoneItem.id}`}
														></DataBuilderGridItem>
													</div>
												</div>
											</div>
										);
									} else if (zoneItem !== undefined) {
										return (
											<Draggable
												key={zoneItem.id}
												draggableId={zoneItem.id}
												index={zoneIndex}
												isDragDisabled={zoneItem.static}
											>
												{(provided: any) => (
													<>
														<div
															ref={
																// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
																provided.innerRef
															}
															{
																// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
																...provided.draggableProps
															}
															{
																// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
																...provided.dragHandleProps
															}
															className={`data-builder-drop-grid-item-wrapper ${
																zone.zoneType ===
																DataBuilderZones.drills
																	? 'secondary-vertical-item'
																	: ''
															} ${
																zoneItem.drilldownActive
																	? 'vertical-item-active'
																	: ''
															}`}
															// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
															style={{
																// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
																...provided.draggableProps.style,
															}}
														>
															<div
																className={
																	'data-builder-drop-grid-item'
																}
															>
																{!zoneItem.static &&
																	zone.zoneType !=
																		DataBuilderZones.drills && (
																		<div
																			{
																				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
																				...provided.dragHandleProps
																			}
																			className={
																				'drag-handle'
																			}
																		>
																			<DragIcon />
																		</div>
																	)}
																<DataBuilderGridItem
																	icon={zoneItem.icon}
																	name={
																		zoneItem.data
																			? zoneItem.data.title
																			: ''
																	}
																	options={getZoneItemOptions(
																		zoneItem,
																		zone.zoneType,
																		zone.hasBreakoutGroups
																	)}
																	handleSelect={(
																		e: string
																	): void => {
																		onZoneItemSelect(
																			e,
																			zoneItem,
																			zone.zoneType
																		);
																	}}
																	onDateSelect={
																		zoneItemDateSelect
																	}
																	optionData={zoneItem}
																	type={zoneItem.itemType}
																	key={`data-builder-grid-item_${zone.zoneType}_${zoneItem.id}`}
																	datePeriodSelectorLabel={
																		datePeriodSelectorLabel
																	}
																	datePeriodSelectorDisabledMessage={
																		datePeriodSelectorDisabledMessage
																	}
																></DataBuilderGridItem>
															</div>
														</div>
													</>
												)}
											</Draggable>
										);
									}
								})}
								{zonesToHighlight.includes(zone.zoneType) && (
									<div className={'drop-indicator'}></div>
								)}
								{
									// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
									provided.placeholder
								}
							</div>
						)}
					</Droppable>
				</div>
			</FormGroup>
		);
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<SVGSVGElement>,
		index: number,
		existingJoinRecordId?: number
	) => {
		if (event.key === ' ') {
			//fix for wierd issue where tooltips still display on key press modal activation
			const tooltipElements: NodeListOf<HTMLElement> =
				document.querySelectorAll('[role="tooltip"]');

			tooltipElements.forEach((tooltip) => {
				if (tooltip) tooltip.remove();
			});

			onOpen(index, existingJoinRecordId);
		}
	};

	const KeyMeasureHtml = (
		<div className={'pf-c-form__group data-builder-drop-grid-zone'}>
			<div className="pf-c-form__group-label mr-10">
				<label className="pf-c-form__label">
					<span className="pf-c-form__label-text">Key Measures</span>
				</label>
			</div>
			<div className="pf-c-form__group-control bold">
				{' '}
				{isLoadingKMs && <Spinner size={'lg'} />}
				{isLoadingKMs == false &&
					chosenKeyMeasures.map((item, index) => {
						const lastItemIdx = chosenKeyMeasures.length - 1;
						const isEvenNumberOrIndexOne = index % 2 == 0 || index == 1;

						if (
							isEvenNumberOrIndexOne &&
							index != lastItemIdx &&
							savedDataframeDataJoins &&
							savedDataframeDataJoins.length > 0
						) {
							const firstKmId = chosenKeyMeasures[index].id;
							const secondKmId = chosenKeyMeasures[index + 1].id;

							const existingJoinRecord = savedDataframeDataJoins.find(
								(x) => x.key_measure_to == secondKmId
							);
							const invalidClass = existingJoinRecord ? 'valid-join' : 'invalid';

							if (existingJoinRecord === undefined) {
								setMinimumOneInvalidJoin && setMinimumOneInvalidJoin(true);
							} else {
								setMinimumOneInvalidJoin && setMinimumOneInvalidJoin(false);
							}

							return (
								<span key={index}>
									<span className="km-block">{item.value}</span>
									{index < keyMeasureNames.length - 1 ? (
										<Tooltip content={'Manage join criteria'}>
											<FontAwesomeIcon
												className={`join-icon ${invalidClass}`}
												icon={faArrowsH}
												onKeyDown={(e) => {
													handleKeyDown(e, index, existingJoinRecord?.id);
												}}
												onClick={() => {
													onOpen(index, existingJoinRecord?.id);
												}}
											/>
										</Tooltip>
									) : (
										''
									)}
								</span>
							);
						} else {
							let invalidClass = 'invalid';
							let tooltipText = 'Manage join criteria';
							const isAdd = !window.location.pathname.includes('dataframes/edit');

							if (keyMeasureNames.length == 1) {
								setMinimumOneInvalidJoin && setMinimumOneInvalidJoin(false);
							}

							if (isAdd) {
								invalidClass = 'disable-join';
								tooltipText =
									'Please submit the Dataframe first before defining Key Measure Joins';
							}
							return (
								<span key={index}>
									<span className="km-block">{item.value}</span>
									{index < keyMeasureNames.length - 1 ? (
										<Tooltip content={tooltipText}>
											<FontAwesomeIcon
												className={`join-icon ${invalidClass}`}
												icon={faArrowsH}
												onClick={() => {
													if (isAdd) {
														return;
													}
													onOpen(index);
												}}
											/>
										</Tooltip>
									) : (
										''
									)}
								</span>
							);
						}
					})}
			</div>
		</div>
	);

	const normalTemplate = () => {
		const zonesHtml = zones.map((zone, index) => {
			return getFormGroupTemplate(zone, index, false, false);
		});
		return (
			<>
				{!props.hideKeyMeasureJoinZone && KeyMeasureHtml} {zonesHtml}
			</>
		);
	};

	const slimTemplate = () => {
		const factZone = zones.find((zone) => zone.zoneType === DataBuilderZones.facts);
		const rowZone = zones.find((zone) => zone.zoneType === DataBuilderZones.rows);
		const filterZone = zones.find((zone) => zone.zoneType === DataBuilderZones.filters);

		return (
			<>
				<div className={'data-builder-drop-grid-groups'}>
					<div className={'data-builder-drop-grid-group'}>
						{factZone && getFormGroupTemplate(factZone, 0, true, false)}

						<div className={'zone-divider'}>by</div>
						{rowZone && getFormGroupTemplate(rowZone, 0, true, false)}
					</div>
					<div className={'data-builder-drop-grid-group'}>
						{filterZone && getFormGroupTemplate(filterZone, 0, false, false)}
					</div>
				</div>
			</>
		);
	};

	const verticalTemplate = () => {
		return zones.map((zone, index) => {
			return getFormGroupTemplate(zone, index, false, true);
		});
	};

	const onClose = () => {
		setIsOpenModal(false);
		setIsLoading(true);
	};

	const Distinct = (array: number[]): number[] => {
		return Array.from(new Set(array));
	};

	const onOpen = (index: number, existingJoinRecordId?: number) => {
		setIsOpenModal(true);
		setChosenCommonDimensions([]);
		loadKeyMeasureJoinModalData(index, existingJoinRecordId);
	};

	const loadKeyMeasureJoinModalData = (index?: number, existingJoinRecordId?: number) => {
		if (existingJoinRecordId) {
			setcurrentSelectedDataframeDataJoinId(existingJoinRecordId);
		}

		const keyMeasuresToAdd: TreeViewDataItem[] = [];

		let firstKmId = 0;
		let secondKmId = 0;
		let selectedKmNames: string[] = [];
		let selectedKmIds: number[] = [];

		if (index || index == 0) {
			const amtToIncrement = index == 0 ? 2 : 1;
			selectedKmIds = chosenKeyMeasures
				.map((x) => x.id)
				.splice(index, index + amtToIncrement);
			selectedKmNames = chosenKeyMeasures
				.map((x) => x.value)
				.splice(index, index + amtToIncrement);

			const existingDataframeDataJoin = savedDataframeDataJoins?.find(
				(x) => x.key_measure_to == selectedKmIds[1]
			);
			firstKmId = existingDataframeDataJoin?.key_measure_from ?? selectedKmIds[0];
			secondKmId = existingDataframeDataJoin?.key_measure_to ?? selectedKmIds[1];

			const actualLeftKmName = existingDataframeDataJoin
				? chosenKeyMeasures.find((x) => x.id == existingDataframeDataJoin.key_measure_from)
						?.value
				: selectedKmNames[0];

			setLeftKmName(actualLeftKmName);
			setLeftKmId(firstKmId);
			setRightKmId(secondKmId);
		} else {
			selectedKmIds = [leftKmId, rightKmId];
			selectedKmNames = [leftKmName ?? '', rightKmName ?? ''];

			firstKmId = leftKmId;
			secondKmId = rightKmId;
		}

		chosenKeyMeasures.map((x) => {
			if (x.id != secondKmId) {
				const itemToAdd: TreeViewDataItem = {
					name: x.value,
					title: x.value,
					id: x.id.toString(),
				};
				keyMeasuresToAdd.push(itemToAdd);
			}
		});

		setAvaliableMeasures(keyMeasuresToAdd);

		const leftKmDims = kmDimensions.find((x) => x.id == firstKmId)?.value;
		const rightKmDims = kmDimensions.find((x) => x.id == secondKmId)?.value;

		const existingJoinRecords = savedDataframeDataJoins
			? savedDataframeDataJoins.filter((x) => x.key_measure_to == secondKmId)
			: [];

		if (existingJoinRecords && existingJoinRecords.length > 0) {
			const firstJoinRecord = existingJoinRecords[0];
			setJoinType(firstJoinRecord.join_type.toLowerCase());

			const existingJoinDimensionIds: number[] = [];

			existingJoinRecords.map((x) => {
				existingJoinDimensionIds.push(x.dimension);
			});

			setExistingJoinDimensionIds(existingJoinDimensionIds);
		} else {
			setExistingJoinDimensionIds([]);
		}

		setSelectedLeftKeyMeasureDims(leftKmDims as number[]);
		setSelectedRightKeyMeasureDims(rightKmDims as number[]);

		setRightKmName(selectedKmNames[1]);
		setSelectedKeyMeasureIDs(selectedKmIds);
		setHasChosenLeftKM(true);
		setHasChosenRightKM(true);
		settriggerKmSelction(!triggerKmSelction);
		setIsLoading(false);
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Key measures were found' }, [])];

	const handleDropdownChangeLeft = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		const value = item.id;
		setLeftKmId(value ? parseInt(value) : 0);
		setLeftKmName(item.name?.toString());

		if (value) {
			setSelectedKeyMeasureIDs([...selectedKeyMeasureIDs, parseInt(value)]);
			setHasChosenLeftKM(true);
			settriggerKmSelction(!triggerKmSelction);
			setTriggerKmDropdownChange(!triggerKmDropdownChange);
		}
	};

	useEffect(() => {
		loadKeyMeasureJoinModalData();
	}, [triggerKmDropdownChange]);

	useEffect(() => {
		if (
			hasChosenLeftKM &&
			hasChosenRightKM &&
			selectedLeftKeyMeasureDims &&
			selectedRightKeyMeasureDims
		) {
			handleMatchingDimensions().catch((error) => {
				console.error('Error occured while fetching & matching dimensions:', error);
			});
		}
	}, [hasChosenLeftKM, hasChosenRightKM, triggerKmSelction]);

	const getDimensions = async (ids: number[]): Promise<Dimension[]> => {
		const promises = ids.map((id) => Dimension.Get(id));
		const responses = await Promise.all(promises);
		return responses.filter((response) => response !== undefined) as Dimension[];
	};

	useEffect(() => {
		void getDimensions(existingJoinDimensionIds).then((existingJoinDimensions) => {
			const mappedJoinDimensions: React.ReactNode[] = existingJoinDimensions.map(
				(option, index) => (
					<div
						key={`option${index + 1}`}
						id={option.id.toString()}
						data-id={option.id}
					>
						{option.display_name ?? option.name}
					</div>
				)
			);

			setChosenCommonDimensions(mappedJoinDimensions);
		});
	}, [existingJoinDimensionIds]);

	const handleMatchingDimensions = async () => {
		const matchingIds: number[] = selectedLeftKeyMeasureDims.filter(
			(item) =>
				selectedRightKeyMeasureDims.includes(item) &&
				!existingJoinDimensionIds.includes(item)
		);

		setIsLoading(true);
		try {
			const commonDimensions = await getDimensions(matchingIds);
			const mappedCommonDimensions: React.ReactNode[] = commonDimensions.map(
				(option, index) => (
					<div
						key={`option${index + 1}`}
						id={option.id.toString()}
						data-id={option.id}
					>
						{option.display_name ?? option.name}
					</div>
				)
			);

			setCommonDimensions(mappedCommonDimensions);
		} catch (error) {
			console.log('Error fetching dimensions:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const onRadioOptionChangeInnerJoin = (
		checked: boolean,
		_event: React.FormEvent<HTMLInputElement>
	) => {
		if (checked == true) {
			setJoinType('inner');
		}
	};

	const onRadioOptionChangeOuterJoin = (
		checked: boolean,
		_event: React.FormEvent<HTMLInputElement>
	) => {
		if (checked == true) {
			setJoinType('outer');
		}
	};

	const saveDataframe = () => {
		const request = buildDataframeRequest(
			props.facts ?? [],
			props.rows ?? [],
			[],
			props.filters ?? [],
			props.name ? props.name : '',
			props.order,
			false,
			props.dataframe ? true : false,
			props.dataframe,
			props.folder
		);

		if (request.id) {
			Dataframe.PatchDataframe(request as TNewDataframe)
				.then((): void => {
					saveAllJoinKeyMeasures();
				})
				.catch((): void => {
					addToast('Error updating dataframe.', AlertVariant.danger);
				});
		} else {
			Dataframe.NewDataframe(request as TNewDataframe)
				.then((response: TDataframe): void => {
					saveAllJoinKeyMeasures();
					const frameId = response.id.toString();

					props.setDataframeId && props.setDataframeId(+frameId);
					props.setDisablePreviewBtn && props.setDisablePreviewBtn(false);
					navigate(`/analyze/dataframes/edit/${frameId}`);

					window.location.reload();
				})
				.catch((): void => {
					addToast('Error creating dataframe.', AlertVariant.danger);
				});
		}
	};

	const saveAllJoinKeyMeasures = () => {
		let request: TDataframeDataJoin = DataframeDataJoin.Default();

		const kmFrom = selectedKeyMeasureIDs[0];
		const kmTo = selectedKeyMeasureIDs[1];

		const dimensionIds: number[] = [];
		if (chosenPaneRef.current) {
			const dimensionElements = chosenPaneRef.current.querySelectorAll('[data-id]');
			dimensionElements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimensionIds.push(parseInt(id));
				}
			});
		}

		request = {
			dataframe_id: props.dataframe?.id ?? 0,
			join_type: joinType,
			key_measure_from: kmFrom,
			key_measure_to: kmTo,
			dimensions: dimensionIds,
		};

		saveJoinKeyMeasureFinal(request);
	};

	const saveJoinKeyMeasureFinal = (finalRequest: TDataframeDataJoin) => {
		DataframeDataJoin.Save(finalRequest)
			.then(() => {
				addToast('Key Measure Joins saved succesfully', AlertVariant.success);
				setIsOpenModal(false);

				fetchDataframeDataJoins && fetchDataframeDataJoins();
				normalTemplate();
			})
			.catch((_error) => {
				addToast(
					'An error occurred while trying to save All the Joins of Key Measures',
					AlertVariant.danger
				);
			});
	};

	return (
		<>
			<Modal
				title={'Joining Key Measures'}
				variant={ModalVariant.large}
				isOpen={isOpenModal}
				onClose={onClose}
			>
				<div>
					<div>
						<span className="spacer-l"></span>
						<Grid span={12}>
							<GridItem span={1}>
								<TextContent className="no-data-headers">
									<Text component={TextVariants.h1}>Join</Text>
								</TextContent>
							</GridItem>
							<GridItem span={3}>
								<SearchableTreeViewSelect
									className="selectDataframe no-innerscroll"
									data-testid="dataframe-tree"
									data={avaliableMeasures ?? emptyTree}
									placeHolderText={leftKmName ?? '<key measure>'}
									onSelect={handleDropdownChangeLeft}
									treeItemsExpanded={true}
									isTopLvlSearchOnly={true}
								/>
							</GridItem>
							<GridItem span={1}></GridItem>
							<GridItem span={1}>
								<TextContent className="no-data-headers">
									<Text component={TextVariants.h1}>To</Text>
								</TextContent>
							</GridItem>
							<GridItem span={3}>
								<TextContent className="no-data-headers">
									<Text component={TextVariants.h2}>{rightKmName}</Text>
								</TextContent>
							</GridItem>
							<GridItem span={3}></GridItem>
						</Grid>
						<br />
						<div className="lbl-jointype">
							<label className="bold">Join Type</label>
							<FormGroup
								role="radiogroup"
								isInline
								fieldId="join-radio-group"
							>
								<Radio
									name="join-radio"
									label="Inner"
									id="join-radio-01"
									onChange={onRadioOptionChangeInnerJoin}
									checked={joinType == 'inner'}
								/>
								<span className="pr-10"></span>
								<Radio
									name="join-radio"
									label="Outer"
									id="join-radio-02"
									onChange={onRadioOptionChangeOuterJoin}
									checked={joinType == 'outer'}
								/>
							</FormGroup>
						</div>
						{isLoading && (
							<>
								<span className="spacer-l"></span>
								<div className="spinner-container">
									<Spinner size={'xl'} />
								</div>
							</>
						)}
						{!isLoading && (
							<>
								<DualListSelectorGeneric
									leftHeading="Avaliable Dimensions to join on"
									rightHeading="Chosen Dimension to Join on"
									leftItems={commonDimensions}
									setLeftItems={setCommonDimensions}
									chosenOptions={chosenCommonDimensions}
									setChosenOptions={setChosenCommonDimensions}
									chosenPaneRef={chosenPaneRef}
								/>
								<br />
							</>
						)}
					</div>

					<ModalBoxFooter className="pull-right modal-share-footer">
						<Button
							variant="primary"
							onClick={() => saveDataframe()}
						>
							Save
						</Button>
					</ModalBoxFooter>
				</div>
			</Modal>
			<div
				className={
					chartId || frameId
						? 'data-builder-drop-grid-container with-share'
						: 'data-builder-drop-grid-container'
				}
			>
				<DragDropContext
					onDragEnd={(e) => {
						onDragEnd(e.source, e.destination);
					}}
				>
					<Form>
						{dropGridType === DataBuilderDropGridTypes.normal && normalTemplate()}
						{dropGridType === DataBuilderDropGridTypes.slim && slimTemplate()}
						{dropGridType === DataBuilderDropGridTypes.empty && verticalTemplate()}
					</Form>
				</DragDropContext>
			</div>
		</>
	);
};

export default DataBuilderDropGrid;
