import axiosConfig from '../axiosConfig';

export type TUserDimensionFilter = {
	id: number;
	user: string;
	dimension: string;
	dimensionValue: string;
	logicGroup: number;
};

export type TNewUserDimensionFilter = Omit<TUserDimensionFilter, 'id'>;

export class UserDimensionFilter implements TUserDimensionFilter {
	id: number;
	user: string;
	dimension: string;
	dimensionValue: string;
	logicGroup: number;

	constructor(udf: TUserDimensionFilter) {
		this.id = udf.id;
		this.user = udf.user;
		this.dimension = udf.dimension;
		this.dimensionValue = udf.dimensionValue;
		this.logicGroup = udf.logicGroup;
	}

	public static Default(): TNewUserDimensionFilter {
		return {
			dimension: '',
			dimensionValue: '',
			logicGroup: 0,
			user: '',
		};
	}

	public static GetAll(
		expand: (keyof TUserDimensionFilter)[] = []
	): Promise<TUserDimensionFilter[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance.get('app/users', { params: { expand: expand } }).then((res) => {
			return ((res.data || []) as TUserDimensionFilter[]).map((entity) => {
				return entity;
			});
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/user/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TUserDimensionFilter): Promise<TUserDimensionFilter> {
		return axiosConfig.instance.post('app/user', entity).then((res) => {
			return res.data as TUserDimensionFilter;
		});
	}

	public static Update(entity: TUserDimensionFilter): Promise<TUserDimensionFilter> {
		return axiosConfig.instance.patch('app/user', entity).then((res) => {
			return res.data as TUserDimensionFilter;
		});
	}
}
