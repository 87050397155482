import axiosConfig from '../axiosConfig';

export type TUserDimensionAttributeFilter = {
	id: number;
	user: string;
	dimensionAttribute: number;
	dimensionAttributeValue: string;
	logicGroup: number;
};

export type TNewUserDimensionAttributeFilter = Omit<TUserDimensionAttributeFilter, 'id'>;

export class UserDimensionAttributeFilter implements TUserDimensionAttributeFilter {
	id: number;
	user: string;
	dimensionAttribute: number;
	dimensionAttributeValue: string;
	logicGroup: number;

	constructor(udf: TUserDimensionAttributeFilter) {
		this.id = udf.id;
		this.user = udf.user;
		this.dimensionAttribute = udf.dimensionAttribute;
		this.dimensionAttributeValue = udf.dimensionAttributeValue;
		this.logicGroup = udf.logicGroup;
	}

	public static Default(): TNewUserDimensionAttributeFilter {
		return {
			dimensionAttribute: 0,
			dimensionAttributeValue: '',
			logicGroup: 0,
			user: '',
		};
	}

	public static GetAll(
		expand: (keyof TNewUserDimensionAttributeFilter)[] = []
	): Promise<TNewUserDimensionAttributeFilter[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance.get('app/users', { params: { expand: expand } }).then((res) => {
			return ((res.data || []) as TNewUserDimensionAttributeFilter[]).map((entity) => {
				return entity;
			});
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/user/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(
		entity: TNewUserDimensionAttributeFilter
	): Promise<TNewUserDimensionAttributeFilter> {
		return axiosConfig.instance.post('app/user', entity).then((res) => {
			return res.data as TNewUserDimensionAttributeFilter;
		});
	}

	public static Update(
		entity: TNewUserDimensionAttributeFilter
	): Promise<TNewUserDimensionAttributeFilter> {
		return axiosConfig.instance.patch('app/user', entity).then((res) => {
			return res.data as TNewUserDimensionAttributeFilter;
		});
	}
}
