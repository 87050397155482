import { TUnitType } from '../api/analytics/UnitType';
import { RoundChartData } from '../types/charts/chart-options';
import { DraggableMenuItemData } from '../types/databuilder/databuilder';
import { calculatePercentage, ChartDataResponse } from './chart.helper';

export const getDefaultSingleSeriesData = (
	chartData: object[],
	unitType?: TUnitType,
	selected2ndUnitType?: TUnitType,
	numberOfDecimals?: number,
	droppedFactsCount?: number,
	chartColor?: string,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean,
	droppedFacts?: DraggableMenuItemData[]
) => {
	const response: ChartDataResponse = {
		xAxisArray: [],
		yAxisArray: [],
		valueArray: [],
		valueArray2: [],
		seriesData: [],
		lookupData: [[], []],
	};
	const singleSeriesFinalArray: RoundChartData = { data: [], data2: [] };

	if (chartData) {
		chartData.map((value) => {
			const hasThree = Object.keys(value).length > 2;
			type ObjectKey = keyof typeof value;
			Object.keys(value).map((key: string, index: number) => {
				const prop = key as ObjectKey;

				if (index === 0) {
					response.xAxisArray.push(value[prop] as string);
				}

				const percentage = calculatePercentage(
					(prop as string).replace(/([A-Z])/g, ' $1').trim(),
					grandTotal,
					value[prop] as number | null | undefined
				);

				const total = !showPercentages
					? (value[prop] as string)
					: percentage
					? percentage.toString()
					: '0';

				if (hasThree) {
					if (index === 1) {
						response.valueArray2.push(total);
					} else if (index === 2) {
						response.valueArray.push(total);
					}
				} else {
					if (index === 1) {
						response.valueArray.push(total);
					}
				}
			});
		});
	}

	if (chartData) {
		const numberOfDecimalsSeries1 =
			droppedFacts && droppedFacts?.length > 0
				? droppedFacts[0]?.data?.numDecimals
				: numberOfDecimals;
		const numberOfDecimalsSeries2 =
			droppedFacts && droppedFacts?.length > 1
				? droppedFacts[1]?.data?.numDecimals
				: numberOfDecimals;

		chartData.map((_value, index) => {
			let plotValue = parseFloat(response.valueArray2[index]);
			let plotValue2 = parseFloat(response.valueArray[index]);
			const categoryValue = response.xAxisArray[index];

			if (!showPercentages) {
				if (unitType?.code === 'PCT') {
					plotValue = plotValue * 100;
				}

				if (
					selected2ndUnitType?.code === 'PCT' ||
					(droppedFactsCount && droppedFactsCount == 1 && unitType?.code === 'PCT')
				) {
					plotValue2 = plotValue2 * 100;
				}
			}

			singleSeriesFinalArray.data.push({
				name: categoryValue,
				y: parseFloat(plotValue.toFixed(numberOfDecimalsSeries1)),
				value: parseFloat(plotValue.toFixed(numberOfDecimalsSeries1)),
			});
			singleSeriesFinalArray.data2.push({
				name: categoryValue,
				y: parseFloat(
					plotValue2.toFixed(
						isNaN(plotValue) ? numberOfDecimalsSeries1 : numberOfDecimalsSeries2
					)
				),
				value: parseFloat(
					plotValue2.toFixed(
						isNaN(plotValue) ? numberOfDecimalsSeries1 : numberOfDecimalsSeries2
					)
				),
			});
		});

		const firstSeriesName =
			droppedFacts && droppedFacts?.length > 0 ? droppedFacts[0]?.data?.title : 'series 1';
		const secondSeriesName =
			droppedFacts && droppedFacts?.length > 1 ? droppedFacts[1]?.data?.title : 'series 2';

		response.seriesData = [
			{
				name: firstSeriesName,
				data:
					droppedFactsCount && droppedFactsCount > 1
						? singleSeriesFinalArray.data
						: singleSeriesFinalArray.data2 ?? [],
				color: chartColor,
			},
		];

		if (droppedFactsCount && droppedFactsCount > 1) {
			response.seriesData.push({
				name: secondSeriesName,
				data: singleSeriesFinalArray.data2 ?? [],
				type: 'line',
				color: '#ABB8C3',
				yAxis: 1,
				colorAxis: 1,
			});
		}
	}

	return response;
};

export const getOdometerData = (
	chartData: object[],
	unitType?: TUnitType,
	numberOfDecimals?: number,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean
) => {
	const response: ChartDataResponse = {
		xAxisArray: [],
		yAxisArray: [],
		valueArray: [],
		valueArray2: [],
		seriesData: [],
		lookupData: [[], []],
	};

	if (chartData) {
		chartData.map((value) => {
			type ObjectKey = keyof typeof value;
			Object.keys(value).map((key: string, index: number) => {
				const prop = key as ObjectKey;

				if (index === 0) {
					response.xAxisArray.push(value[prop] as string);
				}
				if (index === 1) {
					const percentage = calculatePercentage(
						(prop as string).replace(/([A-Z])/g, ' $1').trim(),
						grandTotal,
						value[prop] as number | null | undefined
					);

					response.valueArray.push(
						!showPercentages
							? (value[prop] as string)
							: percentage
							? percentage.toString()
							: '0'
					);
				}
			});
		});

		const odometerFinalArray = chartData.map((_value, index) => {
			let plotValue = parseFloat(response.valueArray[index]);

			if (!showPercentages && unitType?.code === 'PCT') {
				plotValue = plotValue * 100;
			}

			return parseFloat(plotValue.toFixed(numberOfDecimals));
		});

		response.seriesData = [
			{
				name: response.xAxisArray[0],
				data: odometerFinalArray ?? [],
			},
		];
	}

	return response;
};
