import AlertTile, { AlertTileProps } from '../../components/alerts/AlertTile';
import { AlertTrendEnum } from '../../enums/alert-trend.enum';

const AlertCardList: AlertTileProps[] = [
	{
		trend: AlertTrendEnum.Down,
		yoyPercentage: -10,
		handle: '@hr-hr-hadminadmin',
		alertName: 'Turnover Higher In Tech Dept',
		alertValue: 18,
	},
	{
		trend: AlertTrendEnum.Up,
		yoyPercentage: 16,
		handle: '@hr-admin',
		alertName: 'Talent Pool Growing In Skill Areas',
		alertValue: 144,
	},
	{
		trend: AlertTrendEnum.Down,
		yoyPercentage: -12,
		handle: '@hr-admin',
		alertName: 'Diversity Lacking In Age > 50',
		alertValue: 23,
	},
	{
		trend: AlertTrendEnum.NoChange,
		yoyPercentage: 0,
		handle: '@hr-admin',
		alertName: 'Leave Balances Accruing',
		alertValue: 100,
	},
	{
		trend: AlertTrendEnum.Up,
		yoyPercentage: 16,
		handle: '@hr-admin',
		alertName: 'Healthcare Cost Below Budget',
		alertValue: 610598,
	},
	{
		trend: AlertTrendEnum.JustInfo,
		handle: '@hr-admin',
		alertName: 'EOY Reports Due',
		alertValue: 0,
	},
];

const AlertCardsHomePage = () => {
	return (
		<div className={`alert-tile-container ${!AlertCardList.length ? 'no-alerts' : ''}`}>
			{AlertCardList.length
				? AlertCardList.map((alertProps, index) => (
						<AlertTile
							key={index}
							{...alertProps}
						/>
				  ))
				: 'You have no alerts at this time.'}
		</div>
	);
};

export default AlertCardsHomePage;
