import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import {
	TUserDimensionAttributeFilter,
	UserDimensionAttributeFilter,
} from '../../api/security/UserDimensionAttributeFilter';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { Modal, ModalVariant } from '@patternfly/react-core';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { User } from '../../api/security/User';
import { Dimension, TDimension } from '../../api/analytics/Dimension';

export default function UserDimensionFilters(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TUserDimensionAttributeFilter[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [_isUserDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [activeUserDimFilter, setActiveUserDimFilter] = useState<TUserDimensionAttributeFilter>(
		UserDimensionAttributeFilter.Default() as TUserDimensionAttributeFilter
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const [currentUsername, setCurrentUsername] = useState('');
	const { userId } = useParams();

	const selectedColumns: Column<TUserDimensionAttributeFilter>[] = [
		{
			title: 'User',
			columnName: 'user',
		},
		{
			title: 'Dimension Attribute',
			columnName: 'dimensionAttribute',
		},
		{
			title: 'Dimension Attribute Value',
			columnName: 'dimensionAttributeValue',
		},
		{
			title: 'Logic Group',
			columnName: 'logicGroup',
		},
	];

	const actions: Action<TUserDimensionAttributeFilter>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			permission: Permission.EditUser,
			callback: (item) => {
				setIsModalOpen(true);
			},
		},
		{
			name: 'Delete',
			permission: Permission.EditUser,
			callback: (item) => {
				// setActiveUser(item);
				setIsDeleteModalOpen(true);
			},
		},
	];

	const filteredDimensions = dimensions.filter(
		(dimension) => dimension.dimensionAttributes.length > 0
	);

	const formProperties: Field<TUserDimensionAttributeFilter>[] = [
		{
			title: 'User',
			columnName: 'user',
			uiSchema: {
				type: UIType.TEXT,
			},
			disabled: true,
		},
		{
			title: 'Dimension Attribute',
			columnName: 'dimensionAttribute',
			uiSchema: {
				type: UIType.DIM_ATTRIBUTE_SELECT,
				dimOptions: filteredDimensions,
				// initialSelection:
				// 	'id' in activeUser && typeof activeUser.role === 'object'
				// 		? activeUser.role.name
				// 		: undefined,
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Dimension Attribute Value',
			columnName: 'dimensionAttributeValue',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Logic Group',
			columnName: 'logicGroup',
			uiSchema: {
				type: UIType.NUMBER,
			},
		},
	];

	useMount(() => {
		const userIdParsed = userId ? +userId : undefined;

		if (userIdParsed) {
			void User.Get(userIdParsed).then((response) => {
				setCurrentUsername(response.name);
				setActiveUserDimFilter({ ...activeUserDimFilter, user: response.name });
			});
		}

		void Dimension.GetAll(['dimensionAttributes']).then((response) => {
			setDimensions(response);
		});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Users Dimension Attribute Filters"
					pageDescription="Manage Users Dimension Attribute Filters."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			permission={Permission.CreateUser}
			data-testid={'user-dim-attr-filter-create'}
			variant={'primary'}
			onClick={() => {
				setIsModalOpen(true);
			}}
		>
			New User Dimension Attribute Filter
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);
		// User.Delete(activeUser.id)
		// 	.then(() => {
		// 		handleDeleteModalClose();
		// 		setTableLoading(false);
		// 		setTableData((prev) => prev.filter((user) => user.id !== activeUser.id));
		// 		addToast('User deleted successfully.', AlertVariant.success);
		// 	})
		// 	.catch(() => {
		// 		handleDeleteModalClose();
		// 		setTableLoading(false);
		// 		addToast(
		// 			'An error occurred while trying to delete User. Please try again later.',
		// 			AlertVariant.danger
		// 		);
		// 	});
	};

	const handleSuccess = (value: TUserDimensionAttributeFilter) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const userDimFilterTable = (
		<ZiTable<TUserDimensionAttributeFilter>
			ariaLabel={'User Dimension Attribute Filters'}
			columns={selectedColumns}
			data={tableData}
			caption={`User Dimension Attribute Filters for ${currentUsername}`}
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="User Dimension Attribute Filters"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TUserDimensionAttributeFilter>
					title={'User Dimension Attribute Filters'}
					fields={formProperties}
					initialSubject={activeUserDimFilter}
					isLoading={isFormLoading}
					onSubmit={(user) => {
						setIsFormLoading(true);

						// if (user.id) {
						// 	User.Update(user, ['role', 'groups'])
						// 		.then((updated) => {
						// 			handleSuccess(updated);
						// 		})
						// 		.catch(() => {
						// 			addToast(
						// 				'An error occurred while trying to save the User. Please try again later.',
						// 				AlertVariant.danger
						// 			);
						// 		})
						// 		.finally(() => {
						// 			setIsFormLoading(false);
						// 		});
						// } else {
						// 	User.New(user, ['role', 'groups'])
						// 		.then((newUser) => {
						// 			handleSuccess(newUser);
						// 		})
						// 		.catch(() => {
						// 			addToast(
						// 				'An error occurred while trying to save the User. Please try again later.',
						// 				AlertVariant.danger
						// 			);
						// 		})
						// 		.finally(() => {
						// 			setIsFormLoading(false);
						// 		});
						// }
					}}
				/>
			</Modal>

			{/* <Modal
				title="User Delete Confirmation"
				variant="small"
				isOpen={isUserDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>Are you sure you want to delete {activeUser.name}?</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right add-question-footer">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal> */}

			<React.Fragment>
				<FilterTableLayout
					table={userDimFilterTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
