import React, { ReactElement, useEffect, useState } from 'react';
import './LambdaInsights.scss';
import { LambdaInsightsResponse } from '../../api/types';
import CopyButton from '../button/CopyButton';

export type props = {
	insights: LambdaInsightsResponse;
	hasBackground: boolean;
};

const LambdaInsights = (props: props): ReactElement => {
	const { insights, hasBackground } = props;
	const [copyText, setCopyText] = useState('');

	useEffect(() => {
		let finalText = '';
		insights.insights.forEach((insight, index) => {
			if (index === 0) {
				finalText = `Insights\n`;
			}

			finalText = `${finalText}${insight}\n`;
		});

		insights.actions.forEach((action, index) => {
			if (index === 0) {
				finalText = `${finalText}Actions\n`;
			}

			finalText = `${finalText}${action}\n`;
		});

		setCopyText(finalText);
	}, [insights]);

	return (
		<div className={`lambda-insights-container ${hasBackground ? 'insights-background' : ''}`}>
			<div className={'insights'}>
				<div className={'header'}>
					<b>Insights</b>
					<CopyButton
						hasIcon={true}
						textToCopy={copyText}
					/>
				</div>
				{insights.insights.map((insight) => {
					return <div className={'insight-item'}>{insight}</div>;
				})}
			</div>
			<div className={'actions'}>
				<div className={'header'}>
					<b>Actions</b>
				</div>
				{insights.actions.map((action) => {
					return <div className={'insight-item'}>{action}</div>;
				})}
			</div>
		</div>
	);
};

export default LambdaInsights;
