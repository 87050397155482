import React from 'react';
import { PresentationSlide as PresentationSlideType } from '../../types/presentation';
import DropGrid from '../../components/dnd/DropGrid';
import { PresentationWidget } from './PresentationDetail';
import { DashboardWidget } from '../../types/dashboards/dashboard';

type Props = {
	slide: PresentationSlideType;
	handlePresentationWidgetSave: (widget: PresentationWidget) => void;
	removeWidget?: (widget: DashboardWidget) => void;
	handleChartEditClick?: (widget: DashboardWidget) => void;
	gridLayout?: boolean;
};

const PresentationSlide = (props: Props) => {
	const { slide, handlePresentationWidgetSave, removeWidget, handleChartEditClick, gridLayout } =
		props;
	return (
		<DropGrid
			widgets={slide.widgets}
			isEdit
			handleWidgetSave={handlePresentationWidgetSave}
			removeWidget={removeWidget}
			handleChartEditClick={handleChartEditClick}
			gridLayout={gridLayout}
		/>
	);
};

export default PresentationSlide;
