import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import {
	Page,
	PageSection,
	SidebarPanel,
	SidebarContent,
	Sidebar,
	List,
	Spinner,
	SelectOptionObject,
	Tooltip,
} from '@patternfly/react-core';
import Header from './header/Header';
import logo from '../shared/zeroedin-logo_white .png';
import './Layout.scss';
import PageNavigationLinks, { INavigationLink } from './PageNavigationLinks';
import PageTabNavigationItems, { ITabNavigationItem } from './PageTabNavigationItems';
import { Outlet, useLocation } from 'react-router-dom';
import ToastProvider from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import ApplicationProvider from '../components/user/ApplicationProvider';
import { headerMenuItems } from '../helpers/headerHelper';
import SidebarHeaders from './SidebarHeaders';
import { TDataframe } from '../api/types';
import { SideBarSearch } from '../types/sidebar';
import { BackendFactory } from 'dnd-core';
import { capitalizeFirstLetter } from '../utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useParams } from 'react-router-dom';
import WidgetLibrary from '../components/dnd/widgets/WidgetLibrary';
import _ from 'lodash';
import { useIsGranted } from '../components/user/ApplicationProvider';

export interface Folders {
	title: string;
	items: INavigationLink[];
}

export interface SideBar {
	navItems?: INavigationLink[];
	folders?: Folders;
	search?: SideBarSearch;
	isRight?: boolean;
}

export interface IConfig {
	subheaderContext?: ReactElement | null;
	sidebarContext?: INavigationLink[] | null;
	sidebarTabContext?: SideBarTabContext | null;
	sidebarHeaderContext?: SideBarHeaderContext | null;
	subNavExpanded?: boolean;
	setSubNavExpanded?: Dispatch<SetStateAction<boolean>>;
	folderHeadingTitle?: string | null; // Folders
	isLoading?: boolean;
	isRightSideBarLoading?: boolean;
	additionalClassNames?: string | null;
	sidebarSearch?: SideBarSearch;
	rightSideBar?: ReactElement;
	hideLeftSideBar?: boolean;
	folderType?: string;
	reloadFolders?: boolean;
	isFoldersLoading?: boolean;
	isLeftSidebarCollapsable?: boolean;
	startCollapsed?: boolean;
}

export type SubheaderSidebarContext = ((config: IConfig) => void)[];

export type OutletContext = {
	setSubSide: React.Dispatch<React.SetStateAction<IConfig>>;
	subNavExpanded: boolean;
	setSubNavExpanded: React.Dispatch<boolean>;
	folderHeadingTitle?: string | null;
	sidebarTabContext?: SideBarTabContext | null;
	sidebarHeaderContext?: SideBarHeaderContext | null;
	isLoading?: boolean;
	html5Backend?: BackendFactory;
};

export type OutletContextWithGridLayoutProps = {
	gridLayout?: boolean;
	gridBorder?: boolean;
	updateGridLayout?: (value: boolean) => void;
	updateGridBorder?: (value: boolean) => void;
};

export type GridLayoutOutletContext = OutletContext & OutletContextWithGridLayoutProps;
export type GridBorderOutletContext = OutletContext & OutletContextWithGridLayoutProps;

export type SideBarTabContext = {
	tabs: Array<{
		label: string;
		items: ITabNavigationItem[];
	}>;
	hasSearch: boolean;
	dataframes?: TDataframe[];
	onSelectDataframe?: (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		value: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => void;
	searchPlaceholder?: string;
};

export type SideBarHeaderContext = {
	header: string;
	subheader: string;
};

export default function Layout(): ReactElement {
	const header = (
		<Header
			logoImg={logo}
			menuItems={headerMenuItems}
		/>
	);

	const [subNavExpanded, setSubNavExpanded] = useState<boolean>(true);
	const [subSide, setSubSide] = useState<IConfig>({
		subheaderContext: null,
		sidebarContext: null,
		subNavExpanded: subNavExpanded,
		setSubNavExpanded: setSubNavExpanded,
		folderHeadingTitle: '',
		sidebarTabContext: null,
		sidebarHeaderContext: null,
		isLoading: false,
		isRightSideBarLoading: false,
		additionalClassNames: null,
		rightSideBar: undefined,
		hideLeftSideBar: false,
		folderType: undefined,
		reloadFolders: undefined,
		isFoldersLoading: undefined,
		isLeftSidebarCollapsable: false,
		startCollapsed: false,
	});
	const [subheading, setSubheading] = useState<ReactElement | null>(null);
	const [sidebar, setSidebar] = useState<ReactElement | null>(null);
	const [sidebarHeaders, setSidebarHeaders] = useState<ReactElement | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [isRightSideBarLoading, setIsRightSideBarLoading] = useState<boolean>(false);
	const [rightSideBar, setRightSideBar] = useState<ReactElement | null>(null);
	const [rightSideBarOpen, setRightSideBarOpen] = useState<boolean>(true);
	const isGranted = useIsGranted();
	const [leftSideBarOpen, setLeftSideBarOpen] = useState<boolean>(true);

	const outletContext: OutletContext = {
		setSubSide,
		subNavExpanded,
		setSubNavExpanded,
	};

	const location = useLocation();
	const { dashboardId, presentationId, frameId } = useParams();

	useEffect(() => {
		if (subSide.isLeftSidebarCollapsable) {
			setLeftSideBarOpen(!subSide.startCollapsed);
		} else if (!leftSideBarOpen) {
			setLeftSideBarOpen(true);
		}
	}, [subSide.isLeftSidebarCollapsable, subSide.startCollapsed]);

	useEffect(() => {
		if (subSide.subheaderContext) {
			setSubheading(
				<PageSection
					component="div"
					className={`sub-nav ${!subNavExpanded ? 'collapsed' : ''} ${
						subSide.additionalClassNames ? subSide.additionalClassNames : ''
					}`}
				>
					{subSide.subheaderContext}
				</PageSection>
			);
		}
		if (subSide.sidebarContext) {
			setSidebar(
				<PageNavigationLinks
					sidebarLinks={subSide.sidebarContext}
					folderHeadingTitle={subSide.folderHeadingTitle}
					sidebarSearch={subSide.sidebarSearch}
					folderType={subSide.folderType}
					reloadFolders={subSide.reloadFolders}
					isFoldersLoading={subSide.isFoldersLoading}
				/>
			);
		} else if (subSide.hideLeftSideBar) {
			setSidebar(null);
		}

		if (subSide.sidebarTabContext) {
			setSidebar(<PageTabNavigationItems sidebarTabs={subSide.sidebarTabContext} />);
		}
		if (subSide.sidebarHeaderContext) {
			setSidebarHeaders(<SidebarHeaders sidebarHeaders={subSide.sidebarHeaderContext} />);
		}
		if (subSide.isLoading != undefined) {
			setLoading(subSide.isLoading);
		}

		if (subSide.isRightSideBarLoading != undefined) {
			setIsRightSideBarLoading(subSide.isRightSideBarLoading);
		}

		if (subSide.rightSideBar) {
			setRightSideBar(subSide.rightSideBar);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const ls_rightSideBarData = JSON.parse(
				localStorage.getItem('rightSideBarData') ?? '{}'
			);

			if (!_.isEmpty(ls_rightSideBarData) && (dashboardId || presentationId) && !frameId) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const rightSideBarData: React.ReactElement<
					any,
					string | React.JSXElementConstructor<any>
				> = ls_rightSideBarData;

				setRightSideBar(<WidgetLibrary {...rightSideBarData} />);
			} else {
				setRightSideBar(null);
			}
		}

		let pageTitle = 'ZeroedIn Workforce Analytics';

		if (location.pathname) {
			const firstItem = capitalizeFirstLetter(location.pathname.split('/')[1]);
			pageTitle = `${firstItem} - ${pageTitle}`;
		}

		document.title = pageTitle;
	}, [subSide, setSubSide]);

	return (
		<ToastProvider>
			<ApplicationProvider>
				<Page header={header}>
					{subheading}
					<PageSection
						className="page-content"
						style={{
							minHeight: '500px',
							padding: '0',
							marginRight: '0',
						}}
					>
						<Sidebar className="zi-sidebar">
							{sidebar && (
								<SidebarPanel
									className={`left-sidebar-panel ${
										leftSideBarOpen ? 'open' : 'closed-left'
									}`}
								>
									{loading ? (
										<div className="loading-container">
											<Spinner size={'xl'} />
										</div>
									) : (
										<React.Fragment>
											{sidebarHeaders}
											{subSide.isLeftSidebarCollapsable && (
												<Tooltip content={'Toggle Menu'}>
													<FontAwesomeIcon
														icon={
															leftSideBarOpen
																? faAngleLeft
																: faAngleRight
														}
														size="2xl"
														className="collapse-icon-left"
														onClick={() =>
															setLeftSideBarOpen(!leftSideBarOpen)
														}
													/>
												</Tooltip>
											)}

											<List
												className="zi-sidebar-links"
												isPlain
											>
												{sidebar}
											</List>
										</React.Fragment>
									)}
								</SidebarPanel>
							)}
							<SidebarContent
								className={`page-main-content ${sidebar ? '' : 'no-side-bar'}`}
							>
								<Outlet context={outletContext} />
							</SidebarContent>
							{rightSideBar && (
								<SidebarPanel
									className={`right-sidebar-panel ${
										rightSideBarOpen ? 'open' : 'closed'
									}`}
								>
									{isRightSideBarLoading ? (
										<div className="loading-container">
											<Spinner size={'xl'} />
										</div>
									) : (
										<>
											<Tooltip content={'Toggle Menu'}>
												<FontAwesomeIcon
													icon={
														rightSideBarOpen
															? faAngleRight
															: faAngleLeft
													}
													size="2xl"
													className="collapse-icon"
													onClick={() =>
														setRightSideBarOpen(!rightSideBarOpen)
													}
												/>
											</Tooltip>
											<List
												className="zi-sidebar-links"
												isPlain
											>
												{rightSideBar}
											</List>
										</>
									)}
								</SidebarPanel>
							)}
						</Sidebar>
					</PageSection>
				</Page>
			</ApplicationProvider>
		</ToastProvider>
	);
}
